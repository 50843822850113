import React from "react"
import styles from './tangled-line.module.scss'

const TangledLine = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.straightLine}></div>
        <div className={styles.tangledLine}></div>
      </div>
    </>
  )
}

export default TangledLine
