import React from "react"

import styles from './section-header.module.scss'

const SectionHeader = ({ title, description, center, longDescription }) => (
  <div className={ center ? styles.center : '' }>
    <div className={ styles.title }>{ title }</div>
    <div className={ `${styles.description} ${longDescription && styles.descriptionLong}` }>{ description }</div>
  </div>
)

export default SectionHeader
