import React from "react"
import styles from './service.module.scss'
import { SubsectionHeader, SubsectionDescription, SubsectionHelper } from 'components/home/subsection/subsection'

const Service = ({ title, description, helper, image }) => (
  <div className={styles.service}>
    <SubsectionHeader>{title}</SubsectionHeader>
      <div className={`${styles.imageWrapper} ungutter`}>
        <img src={image} className={styles.image} alt={title} />
      </div>
      <SubsectionDescription>{ description }</SubsectionDescription>
      <SubsectionHelper>{ helper }</SubsectionHelper>
  </div>
)

export default Service
