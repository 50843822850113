import React from "react"
import SectionHeader from 'components/home/section-header/section-header'
import styles from './values.module.scss'
import { SubsectionHeader, SubsectionDescription } from 'components/home/subsection/subsection'
import { Container } from "components/layout/container"

const Values = () => (
  <div id="values" className={styles.valuesWrap}>
    <Container>
      <SectionHeader title="Values We Hold Dear" description="Our work is built upon the following core values from which we never diverge." />

      <div className={styles.values}>
        <div className={styles.value}>
          <SubsectionHeader>Quality</SubsectionHeader>
          <SubsectionDescription>
            <div className={styles.valueDescription}>
              We are very quality oriented in everything we do. We write clean code and produce
              pixel-perfect designs. Details get plenty of our attention.
            </div>
          </SubsectionDescription>
        </div>

        <div className={styles.value}>
          <SubsectionHeader>Originality</SubsectionHeader>
          <SubsectionDescription>
            <div className={styles.valueDescription}>
              We value creativity and talent. We value other people’s work. The work we produce always
              comes from our talented team and we are proud to call it ours.
            </div>
          </SubsectionDescription>
        </div>

        <div className={styles.value}>
          <SubsectionHeader>Performance</SubsectionHeader>
          <SubsectionDescription>
            <div className={styles.valueDescription}>
              We hate slow tech and we vow to produce only performant solutions. People should love
              products we build. Machines should not have trouble running our code.
            </div>
          </SubsectionDescription>
        </div>
      </div>
    </Container>
  </div>
)

export default Values
