import React from "react"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styles from './contact.module.scss'
import SectionHeader from 'components/home/section-header/section-header'
import ContactForm from './contact-form/contact-form'

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      happyBunchImage: file(relativePath: { eq: "home-page/happy-bunch@2x.jpg" }) {
        childImageSharp {
          fixed(width: 450) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <div id="contact" className={`container ${styles.container}`}>
      <SectionHeader
        title="Let’s build something together"
        description="We can’t wait to start working with you. Let’s make your next project a success." />
      <div className={styles.contact}>
        <div className={styles.contact__form}><ContactForm /></div>
        <div className={styles.contact__contacts}>
          <hr className={styles.lineBreak} />
          <div className={styles.contactGroup}>
            <div className={styles.contactLabel}>Email us</div>
            <div className={styles.contactValue}>hello@binarystride.com</div>
          </div>
          <div className={styles.contactGroup}>
            <div className={styles.contactLabel}>Call us</div>
            <div className={styles.contactValue}>+66 83 898 2329 <span className={styles.contactValueCountry}>(Thailand)</span></div>
            <div className={styles.contactValue}>+972 3 376 0678 <span className={styles.contactValueCountry}>(Ukraine)</span></div>
          </div>
          <div className={styles.contactGroup}>
            <div className={styles.contactLabel}>Find us</div>
            <div className={styles.textFindUs}>
              <b>Binary Stride (Thailand)</b><br />
              312 Golden Town, 71 Phayathai Road<br />
              Ratchathewi, Bangkok, Thailand
            </div>
            <div className={styles.textFindUs} style={{ marginTop: '2rem' }}>
              <b>Binary Stride (Ukraine)</b><br />
              Soborna 24, Vinnytsia, Ukraine
            </div>
          </div>
          <div className={styles.happyBunch}>
            <GatsbyImage fixed={data.happyBunchImage.childImageSharp.fixed} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
