import React from "react"

import styles from './tech.module.scss'
import SectionHeader from 'components/home/section-header/section-header'

const TechLine = (techTexts) => {
  const groupTech = (groupOrder, techTexts) => {
    const styleGroupTechAnim1 = groupOrder === 1 ? styles.groupTechAnim1 : ''
    const styleGroupTechAnim2 = groupOrder === 2 ? styles.groupTechAnim2 : ''
    const styleGroupTechAnim3 = groupOrder === 3 ? styles.groupTechAnim3 : ''

    return (
      <div className={`${styles.groupTech} ${styleGroupTechAnim1} ${styleGroupTechAnim2} ${styleGroupTechAnim3}`}>
        {
          techTexts.map((value, index) => (
            <div className={`${styles.techItem}`} key={index}>
              {value === '-' ? <div className={`${styles.techLine}`}></div> : value}
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <div className={styles.rowTech}>
      {groupTech(1, techTexts)}
      {groupTech(2, techTexts)}
      {groupTech(3, techTexts)}
    </div>
  )
}

const Tech = () => {
  return (
    <>
      <div id="tech" className={styles.whole}>
        <SectionHeader title="Tech We Work With" center={true} />
        <div className={styles.tech}>
          {TechLine([null, null, null, 'Nginx', '-', null, 'Digital Ocean', null, null, '-', null])}
          {TechLine(['-', 'Angular', null, null, 'TypeScript', null, null, '-', 'Node.js', null, null])}
          {TechLine([null, null, null, 'Docker', null, '-', 'React', null, null, null, 'WebSockets'])}
          {TechLine([null, null, '-', null, null, 'Vue.js', null, 'Laravel', null, 'Chef', '-'])}
          {TechLine([null, 'MongoDB', null, 'Redis', '-', null, 'MySQL', '-', 'AWS', '-', 'Google Cloud'])}
        </div>
      </div>
    </>
  )
}

export default Tech
