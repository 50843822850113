import React from 'react'
import styles from './pops-esports.module.scss'
import { SubsectionHeader, SubsectionDescription, SubsectionHelper } from 'components/home/subsection/subsection'

const PopsEsports = () => {
  return (
    <div className={styles.container}>
      <div className={styles.bgImage}>
        <div className={styles.bgGradient} />
      </div>
      <div className={styles.info}>
        <SubsectionHeader>POPS Esports</SubsectionHeader>
        <SubsectionDescription color="#ABB3C7">A platform for organizing and hosting esports tournaments.</SubsectionDescription>
        <div className={styles.subsectionHelper}>
          <SubsectionHelper color="#ABB3C7">Zero-to-Hero Full-Stack Development, UI/UX Design</SubsectionHelper>
        </div>
      </div>
    </div>
  )
}

export default PopsEsports
