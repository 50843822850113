import React from "react"
import styles from './home.module.scss'
import Values from './values/values'
import Services from './services/services'
import BuiltHere from './built-here/built-here'
import Tech from './tech/tech'
import Contact from './contact/contact'
import HeroLine from './hero-line/hero-line'
import TangledLine from './tangled-line/tangled-line'
import WhoWeAre from './who-we-are/who-we-are'

const Home = () => {
  return (
    <>
      <div className={styles.mainHero}>
        <div className="container" style={{position: "relative"}}>
          <div className={styles.mainHeroBg}></div>
          <p className={styles.headline}>
            We will <b><span className={styles.underlined}>design</span> </b>
            and <b><span className={styles.underlined}>engineer</span></b> your next success.
          </p>
          <p className={styles.subtitle}>
            Binary Stride is a quality-first software engineering company that will help you build your next product or service.
          </p>
        </div>
        <HeroLine />
      </div>
      <Values />
      <hr className={styles.lineBreak} />
      <Services />
      <TangledLine />
      <BuiltHere />
      <Tech />
      <WhoWeAre />
      <hr className={styles.lineBreak} />
      <Contact />
    </>
  )
}

export default Home
