import React from "react"
import GastbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styles from './built-here.module.scss'
import SectionHeader from 'components/home/section-header/section-header'
import Project from './project/project'
import PopsEsports from './pops-esports/pops-esports'

const BuiltHere = () => {
  const data = useStaticQuery(graphql`
    query {
      croodsImage: file(relativePath: { eq: "home-page/croods@2x.jpg" }) {
        childImageSharp {
          fixed(width: 377) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      },
      pubopsImage: file(relativePath: { eq: "home-page/pubops@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 576) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      futitadaImage: file(relativePath: { eq: "home-page/futitada@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 576) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <div id="built_here" className="container">
        <SectionHeader
          title="Built Here With 💜"
          description="Here are some of the latest projects that we’ve worked on."
          center={true} />
        <div style={{ marginTop: '4rem' }}>
          <div className="ungutter">
            <PopsEsports />
          </div>
        </div>
        <div className={styles.projects}>
          <Project
            title="Pubops"
            description="An ambitious project with sales automation and business intelligence at its heart."
            helper="Full-Stack Product Design and Development"
            fluidImage={data.pubopsImage.childImageSharp.fluid} />
          <Project
            title="Futitada"
            description="Real-time football data analytics and aggregation engine coupled with engaging UI."
            helper="Data Modeling And Full-Stack Product Development"
            fluidImage={data.futitadaImage.childImageSharp.fluid} />
        </div>
        <div className={styles.clients}>
          <SectionHeader title="More Happy Clients" center={true} />
          <div className={styles.clientList}>
            <div className={styles.textClient}>Brand Name Gossip</div>
            <div className={styles.textClient}>ShopyThai</div>
            <div className={styles.textClient}>iPS Media</div>
          </div>
        </div>
      </div>
      <div className={styles.croods}>
        <GastbyImage fixed={data.croodsImage.childImageSharp.fixed} />
      </div>
    </>
  )
}

export default BuiltHere
