import React from "react"
import styles from './hero-line.module.scss'
import HeroLinesImage from 'images/home-page/hero-lines.svg'

const HeroLine = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.line}></div>
      <div className={styles.arrows}>
        <img src={HeroLinesImage} alt="Arrows" className={styles.arrowsImage} />
      </div>
    </div>
  )
}

export default HeroLine
