import React from "react"
import styles from './subsection.module.scss'

export const SubsectionHeader = ({ children }) => {
  return (
    <div className={styles.header}>
      { children }
    </div>
  )
}

export const SubsectionDescription = ({ children, color }) => {
  return (
    <div className={styles.description} style={{ ...(color && { color }) }}>
      { children }
    </div>
  )
}

export const SubsectionHelper = ({ children, color }) => {
  return (
    <div className={styles.helper} style={{ ...(color && { color }) }}>
      { children }
    </div>
  )
}
