import React from "react"
import styles from './project.module.scss'
import { SubsectionHeader, SubsectionDescription, SubsectionHelper } from 'components/home/subsection/subsection'
import GatsbyImage from "gatsby-image"

const Project = ({ title, description, helper, fluidImage }) => {
  return (
    <div className={`${styles.project} ungutter`}>
      <GatsbyImage fluid={{ ...fluidImage, sizes: '(max-width: 575px) 100vw, (max-width: 767px) 512px, 416px' }} alt={title} className={styles.image}></GatsbyImage>
      <div className={styles.info}>
        <SubsectionHeader>{title}</SubsectionHeader>
        <SubsectionDescription>{ description }</SubsectionDescription>
        <SubsectionHelper>{ helper }</SubsectionHelper>
      </div>
    </div>
  )
}

export default Project
