import React from "react"

import styles from './contact-form.module.scss'

export default class ContactForm extends React.Component {
  state = {
    message: '',
    name: '',
    email: '',
    phone: '',
    hearAboutUs: '',
    loading: false
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleError = () => {
    alert("Ooops, your message was not sent. Could you please try again?")
    this.setState({ loading: false })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({ loading: true })
    fetch('/api/send-contact-message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...this.state, loading: undefined })
    })
      .then(res => res.json())
      .then((res) => {
        if (res.success) {
          window.location.href = '/contact-submitted'
        } else {
          this.handleError()
        }
      })
      .catch(() => {
        this.handleError()
      })
  }

  validateForm = () => this.state.message !== '' && this.state.name !== '' && this.state.email !== ''
  disableForm = () => !this.validateForm() || this.state.loading

  render() {
    return (
      <form onSubmit={this.handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="contact_message" className={styles.label}>What can we do for you?</label>
          <textarea id="contact_message" name="message" rows="5" className={styles.input} value={this.state.message} onChange={this.handleInputChange}></textarea>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="contact_name" className={styles.label}>Your name</label>
          <input id="contact_name" name="name" type="text" className={styles.input} value={this.state.name} onChange={this.handleInputChange} />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="contact_email" className={styles.label}>Your email</label>
          <input id="contact_email" name="email" type="email" className={styles.input} value={this.state.email} onChange={this.handleInputChange} />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="contact_phone" className={styles.label}>Phone (optional)</label>
          <input id="contact_phone" name="phone" type="tel" className={styles.input} value={this.state.phone} onChange={this.handleInputChange} />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="contact_source" className={styles.label}>How did you hear about Binary Stride? (optional)</label>
          <input id="contact_source" name="hearAboutUs" type="text" className={styles.input} value={this.state.hearAboutUs} onChange={this.handleInputChange} />
        </div>
        <button type="submit" className={styles.button} disabled={this.disableForm()}>Send message</button>
        <div className={styles.textHelper}>
          {this.state.loading ? 'Sending...' : 'We’ll get back to you shortly.'}
        </div>
      </form>
    )
  }
}
