import React from 'react';
import SectionHeader from 'components/home/section-header/section-header';
import styles from './who-we-are.module.scss';
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"

const Person = ({ name, position, headline, fluidImage, linkedInLink }) => {
  return (
    <div className={styles.person}>
      <div className={styles.personImageWrapper}>
        <span className={styles.personHeadline}>{headline}</span>
        <GatsbyImage fluid={fluidImage} className={styles.personImg} alt={name} />
        <a href={linkedInLink} target="_blank" rel="noreferrer" className={styles.btnLinkedIn}>in</a>
      </div>
      <div className={styles.textPersonName}>{name}</div>
      <div className={styles.textPersonPosition}>{position}</div>
    </div>
  );
};

const WhoWeAre = () => {
  const data = useStaticQuery(graphql`
    query {
      denisImage: file(relativePath: { eq: "people/denis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 265, srcSetBreakpoints: [300]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      denysImage: file(relativePath: { eq: "people/denys.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 265, srcSetBreakpoints: [300]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `)

  return (
    <div className="container">
      <SectionHeader
        title="Who We Are"
        description="Our primary drive mechanism is simple yet effective – the success of your business is our success. Whether you are a startup or an established business, we at Binary Stride are fully committed to helping your business grow as if it was our own."
        center={true}
        longDescription={true} />
      <div className={styles.people}>
        <Person
          name="Denis Pshenov"
          position="Founder & CEO"
          headline="Loves clean code"
          fluidImage={data.denisImage.childImageSharp.fluid}
          linkedInLink="https://www.linkedin.com/in/denis-pshenov/" />
        <Person
          name="Denys Kantorsky"
          position="Chief Operating Officer"
          headline="Doesn't micromanage"
          fluidImage={data.denysImage.childImageSharp.fluid}
          linkedInLink="https://www.linkedin.com/in/denys-kantorsky-a3587a83/" />
      </div>
    </div>
  );
};

export default WhoWeAre;
