import React from "react"

import SectionHeader from 'components/home/section-header/section-header'
import styles from './services.module.scss'
import Service from './service/service'

import ImageServiceDesign from 'images/home-page/schematic.svg'
import ImageServiceEngineering from 'images/home-page/brain.svg'
import ImageServiceDevOps from 'images/home-page/linesup.svg'

const Services = () => (
  <div id="services" className="container">
    <div style={{ maxWidth: "64rem" }}>
      <SectionHeader title="What We Do" description="We bring your ideas to life. Our work starts with the product design and then goes all the way to its delivery to millions of people." />
    </div>
    <div className={styles.services}>
      <Service
        title="Design"
        description="Your next product will be beautiful but at the same time functional and user friendly. We’ll make sure it’s accessible and has great UX. You’ll stand out from the rest."
        helper="UI/UX Design • Wireframing/Prototyping • Brand Identity Design"
        image={ImageServiceDesign} />
      <Service
        title="Engineering"
        description="Our battle-tested rockstar engineers will produce high-quality code and build custom solutions for your business that will give you an edge over the competition."
        helper="Web/App Development • API Development • Custom Solutions"
        image={ImageServiceEngineering} />
      <Service
        title="DevOps"
        description="We will set up continuous integration and continuous delivery pipelines for your development workflows. We will serve your product to millions of people without a hitch."
        helper="CI/CD Pipelines • Server Management • Cloud Services"
        image={ImageServiceDevOps} />
    </div>
  </div>
)

export default Services
