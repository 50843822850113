import React from "react"

import Layout from 'components/layout/layout'
import Home from 'components/home/home'
import SEO from 'components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Software Engineering Company in Bangkok" />
    <Home />
  </Layout>
)

export default IndexPage
